:root {
  --main-bg-color: #ffffff;
  --lightgray: #cccccc;
  --main-font-color: #333333;
  --main-font-color-light: #666666;
  --header-bg: #33333333;
  --box-shadow-color: #aaaaaa;
  --danger: #881c1c;
  --hung-gar: #c91b29;
  --wing-chun: green;
  --funcional: #576b95;
  --sticky-header-height: 60px;
  --title-size: 2rem;
  --subtitle-1-size: 1.6rem;
  --subtitle-2-size: 1.4rem;
  --paragraph-size: 1.2rem;
}

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  text-decoration: none;
  color: var(--main-font-color);
}

a {
  font-weight: bold;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  background-color: var(--main-bg-color);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
