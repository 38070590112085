.loading-component {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    height: calc(100vh - 150px);
}

.loading-component-spinner {
    position: absolute;
    border-top: 10px solid;
    border-radius: 50%;
    height: 250px;
    width: 250px;
    animation-name: spin;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    animation-timing-function: linear;
}

@keyframes spin { 
    from { 
        transform: rotate(0deg); 
    } to { 
        transform: rotate(360deg); 
    }
}